<template>
  <a-modal title="自定义调整" :visible="show" @cancel="cancel" :destroyOnClose="true" :footer="null" width="35%">
    <a-form-model :layout="formLayout" ref="ruleForm" :model="form" :rules="rules"
      :labelCol="{ span: 6 }" :wrapperCol="{ span: 14 }">
      <a-form-model-item label="自定义类型" prop="customType">
        <a-radio-group v-model="form.customType">
          <a-radio :value="0">
            上调
          </a-radio>
          <a-radio :value="1">
            下调
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="自定义分值" prop="customScore">
        <a-input v-model="form.customScore" placeholder="请输入" style="width: 100%;" @input="inputFilter($event)" />
      </a-form-model-item>
      <div style="width: 100%; display: flex;justify-content: space-evenly;">
        <a-button type="primary" @click="save"> 保存 </a-button>
        <a-button @click="cancel"> 关闭 </a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { moneyFilter } from '@/utils/inputVerify';
import * as api from "@/api/ranking";
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    actualScore: {
      type: Number,
      default: () => 0
    },
    id: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    id: {
      handler(newVal) {
        if (newVal) {
          this.form.id = newVal;
        }
      },
      immediate: true
    },
    'form.customType': {
      handler(newVal) {
        this.form.customScore = ''
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      formLayout: 'horizontal',
      form: {
        id: '',
        customType: 0,
        customScore: ''
      },
      moneyFilter,
      rules: {
        customType: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        customScore: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {
  },
  methods: {
    inputFilter(e) {
      let max;
      if (this.form.customType === 1) {
        max = this.actualScore;
      } else if (this.form.customType === 0) {
        max = 5 - this.actualScore;
      }
      const filterData = moneyFilter(e.target.value, max, 0);
      console.log(filterData);
      this.form.customScore = filterData.length > 4 ? (filterData * 100).toFixed() / 100 : filterData;
    },
    blurFilter() {
      this.form.customScore =  (this.form.customScore * 100).toFixed() / 100;
    },
    //点击关闭
    cancel() {
      this.$emit("cancel")
    },
    //点击提交
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    /**网络请求 */
    // 提交数据
    async submit() {
      try {
        const res = await api.adjustmentCustomScore(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.cancel();
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error('操作失败，请重试');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>